import React from 'react'

import { PageHeader, Seo } from '../../common'

import * as styles from './mass-transfer.module.scss'
import { Link } from 'gatsby'
import { Paths } from '../../../constants/structure'

const MassTransfer = () => {
   return (
      <>
         <Seo title="Mass Transfer" />
         <PageHeader title="Mass Transfer" />

         <div className={styles.widgetContainer}>
            <h3 className={styles.placeholderTitle}>
               Mass Transfer functionality has moved to the Actions menu.
            </h3>
            <p>
               Simply use the Filter button to view Items by Connection or
               Location, click Add All to move to the Cart, then Check Out to
               create the Transfer.
            </p>

            <Link to={Paths.ACTIONS}>Go to Actions</Link>
            {/* <Row gutterWidth={40}>
               <Col xs={12} md={4} lg={4}>
                  <Widget heading="Connection">
                     <p>
                        Mass transfer items from one connection to another
                        connection.
                     </p>
                     <TransferByConnectionForm />
                  </Widget>
               </Col>
               <Col xs={12} md={4} lg={4}>
                  <Widget>
                     <MassTransferDivider />
                  </Widget>
               </Col>
               <Col xs={12} md={4} lg={4}>
                  <Widget heading="Location">
                     <p>
                        Mass transfer items from one job location to another job
                        location.
                     </p>
                     <TransferByLocationForm />
                  </Widget>
               </Col>
            </Row> */}
         </div>
      </>
   )
}

export default MassTransfer
